import type { App } from 'vue'
import type { LogHandler, LogMessage } from 'zeed'
import * as Sentry from '@sentry/vue'
import { getGlobalLogger, LogLevelDebug, LogLevelError, LogLevelInfo, LogLevelWarn, renderMessages, valueToBoolean } from 'zeed'

export function setupSentry() {
  const level: number = LogLevelDebug

  function LoggerSentryBreadcrumbHandler(level: number = LogLevelDebug): LogHandler {
    return (msg: LogMessage) => {
      if (msg.level < level)
        return
      const message = renderMessages(msg.messages)
      const category = msg.name || 'log'

      switch (msg.level) {
        case LogLevelError:
          Sentry.addBreadcrumb({ category, message, level: 'error' })
          break
        case LogLevelWarn:
          Sentry.addBreadcrumb({ category, message, level: 'warning' })
          break
        case LogLevelInfo:
          Sentry.addBreadcrumb({ category, message, level: 'info' })
          break
        case LogLevelDebug:
          Sentry.addBreadcrumb({ category, message, level: 'debug' })
          break
      }

      // Should we capture the error? Ignore uncatced ones, because they are already captured by Sentry itself.
      if (msg.level === LogLevelError && !message.startsWith('Catched unhandled ')) {
        Sentry.withScope(() => {
          const error = msg.messages.find((m: any) => m instanceof Error || m instanceof ErrorEvent)
          if (error) {
            if (error instanceof ErrorEvent)
              Sentry.captureException(error.error)
            else
              Sentry.captureException(error)
          }
          else {
            Sentry.captureException(new Error(message))
            // Sentry.captureMessage(message)
          }
        })
      }
    }
  }

  const handler = LoggerSentryBreadcrumbHandler(level)

  // From now on the log messages will be sent to Sentry
  getGlobalLogger().registerHandler(handler)

  // Example bug triggers
  import('./bug-triggers')
    .then(({ setupBugTriggers }) => setupBugTriggers())
    .catch(err => console.error('bug triggers not loaded', err))
}

export function setupSentryForVue(app: App) {
  if (DEBUG)
    return

  if (!import.meta.env.APP_SENTRY_DSN)
    return

  Sentry.init({
    app,
    dsn: import.meta.env.APP_SENTRY_DSN,
    release: import.meta.env.APP_RELEASE || undefined,
    autoSessionTracking: valueToBoolean(import.meta.env.APP_BETA, false),
    // logErrors: false,
    // ignoreErrors: [
    //   'ResizeObserver loop limit exceeded',
    // ]
  })

  setupSentry()
}
