import { logCaptureConsole, Logger } from 'zeed'

export function setupLogging() {
  if (typeof localStorage !== 'undefined' && !localStorage.getItem('zeed')) {
    const log = Logger('console')
    logCaptureConsole(log)

    window.addEventListener('error', (event) => {
      log.error('Catched unhandled error', event)
    })

    window.addEventListener('unhandledrejection', (event) => {
      log.error('Catched unhandled rejection', event)
    })

    log.info('capturing mode for console')
  }
}
