import type { PostMeta } from '@/_types'
import { getRoutesStandard, routesStandard } from '@/router-standard'
import _postsData from './_posts/meta.json'
import { siteConfig } from './config'

const postsData = _postsData as unknown as PostMeta[]

export const routes = getRoutesStandard({
  siteConfig,
  pagesImport: import.meta.glob('./_pages/*.md', { eager: false }),
  postsImport: import.meta.glob('./_posts/*.md', { eager: false }),
  postsData,
})

routes.push(...routesStandard())

for (const lang of siteConfig.languages) {
  routes.push({
    path: `/${lang}`,
    meta: {
      pure: true,
      // comp: () => defineAsyncComponent(() => import(`./_content/intro-${lang}.md`)),
      comp: () => import(`./_content/intro-${lang}.md`),
    },
    component: () => import('./app-homepage.vue'),
  })
}
